<script lang="ts">
  import { onMount } from "svelte";
  import { fetchTournamentLeaderBoard } from "$lib/services/tournaments";
  import { writable } from "svelte/store";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Icons from "$lib/main/components/Icons.svelte";

  let winners = writable([]);
  let tournamentId = 379;

  export let props = {
    title: "",
  };
  export let title = "";
  export let adminView = false;

  onMount(async () => {
    try {
      const leaderboard = await fetchTournamentLeaderBoard(tournamentId);
      winners.set(leaderboard.slice(0, 3));
    } catch (error) {
      console.error("Failed to fetch leaderboard:", error);
    }
  });

  const getPrizeText = (index: number) => {
    switch (index) {
      case 0:
        return "1st prize";
      case 1:
        return "2nd prize";
      case 2:
        return "3rd prize";
      default:
        return "";
    }
  };

  const getPrizeColor = (index: number) => {
    switch (index) {
      case 0:
        return "#FFCF3A";
      case 1:
        return "#D1D1D1";
      case 2:
        return "#D8754F";
      default:
        return "transparent";
    }
  };
</script>

{#if adminView}
  <Group colspan="4-8">
    <Input
      label="Component Title"
      type="text"
      bind:value={props.title}
      on:change
    />
  </Group>
{:else}
  <div class="container">
    <h2>{props.title || title}</h2>
    <div class="winners-grid">
      {#each $winners as winner, index}
        <div
          class="winner-card"
          class:gold-border={index === 0}
          class:silver-border={index === 1}
          class:bronze-border={index === 2}
        >
          <div class="trophy-icon">
            <Icons
              name="trophy"
              fill={getPrizeColor(index)}
              width="32px"
              height="32px"
              strokeWidth="0"
            />
            <div class="prize-text" style="color: {getPrizeColor(index)};">
              {getPrizeText(index)}
            </div>
          </div>
          <div class="winner-info">
            <div class="winner-name">{winner.playerName}</div>
            <div class="winner-score">{winner.playerScore} points</div>
          </div>
        </div>
      {/each}
    </div>
  </div>
{/if}

<style>
  .winners-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    padding: 16px;
    @media only screen and (min-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .winner-card {
    display: flex;
    height: 70px;
    align-items: center;
    border: 2px solid transparent;
    gap: 16px;
    border-radius: 8px;
    background-color: #717171;
    color: #fff;
    overflow: hidden;
  }

  .trophy-icon {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    padding: 8px 16px;
    height: 100%;
    background-color: #3f3f3f;
  }

  .winner-info {
    display: flex;
    flex-direction: column;
  }

  .winner-name {
    font-size: 18px;
    font-weight: bold;
  }

  .winner-score {
    font-size: 14px;
  }

  .prize-text {
    width: min-content;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  .gold-border {
    border-color: #ffcf3a;
  }

  .silver-border {
    border-color: #d1d1d1;
  }

  .bronze-border {
    border-color: #d8754f;
  }
</style>
