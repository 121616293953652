<script>
  import PreviewContent from "$lib/admin/components/content/PreviewContent.svelte";
  import {
    postData,
    postUsers,
    projectToplists,
    orgOperators,
    orgCasinoGames,
    orgGameProviders,
    lotteryResults,
    project,
  } from "$lib/services/store";
  import { onMount } from "svelte";
  import { isTournamentsRoute } from "$lib/services/utils";

  export let data;

  let { post, session } = data;
  let TournamentPage = null;
  let isTournamentPage = false;

  $: {
    postUsers.set(data.usersData);
    postData.set(data.post);
    projectToplists.set(data.toplistData);
    orgOperators.set(data.operatorData);
    orgCasinoGames.set(data.casinoGamesData);
    orgGameProviders.set(data.gameProvidersData);
    lotteryResults.set(data.lotteryResultsData);
  }

  const tournamentRoute = $project.settings.tournamentUrl ?? "tournaments";
  const tournamentsSlug = "tournaments";
  isTournamentPage = post.slug.startsWith(`/${tournamentsSlug}/`);

  onMount(async () => {
    if (isTournamentPage) {
      const module = await import(
        "$lib/admin/components/blocks/blocks/Tournaments/TournamentPage.svelte"
      );
      TournamentPage = module.default;
    }
  });
</script>

<svelte:head>
  <title
    >{post.seo.page_title
      ? post.seo.page_title
      : post.title
        ? `${post.title} - ${$project.name}`
        : "No page title set - " + post.project_key.toUpperCase()}</title
  >
  <meta
    name="description"
    content={post.seo.meta_description &&
    post.parent_id === $project.data.categoryPages.news.id
      ? post.seo.meta_description.substring(0, 149)
      : post.seo.meta_description
        ? post.seo.meta_description
        : post.content.find((block) => block.blockType === "Intro")
          ? post.content
              .find((block) => block.blockType === "Intro")
              ?.props.content.replace(/(<([^>]+)>)/gi, "")
              .substring(0, 149)
          : post.content.find((block) => block.blockType === "Content")
            ? post.content
                .find((block) => block.blockType === "Content")
                ?.props.content.replace(/(<([^>]+)>)/gi, "")
                .substring(0, 149)
            : "No meta description set"}
  />
</svelte:head>

{#if isTournamentPage && TournamentPage}
  <svelte:component
    this={TournamentPage}
    tournamentId={post.tournamentId}
    sponsoredBy={post.sponsoredBy}
    tournamentToplist={post.tournamentToplist}
  />
{:else}
  <PreviewContent {post} {session} />
{/if}
