<script lang="ts">
  import { onMount } from "svelte";
  import { writable } from "svelte/store";

  export let endTime: number;

  const days = writable(0);
  const hours = writable(0);
  const minutes = writable(0);

  const updateCountdown = () => {
    const now = Math.floor(Date.now() / 1000);
    const remainingTime = endTime - now;

    days.set(Math.floor(remainingTime / (3600 * 24)));
    hours.set(Math.floor((remainingTime % (3600 * 24)) / 3600));
    minutes.set(Math.floor((remainingTime % 3600) / 60));
  };

  onMount(() => {
    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  });
</script>

<div class="countdown">
  <div class="unit">
    <div class="value">{$days}</div>
    <div class="label">Days</div>
  </div>
  <div class="separator">:</div>
  <div class="unit">
    <div class="value">{$hours}</div>
    <div class="label">Hours</div>
  </div>
  <div class="separator">:</div>
  <div class="unit">
    <div class="value">{$minutes}</div>
    <div class="label">Minutes</div>
  </div>
</div>

<style>
  .countdown {
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
  }
  .unit {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .value {
    font-size: 24px;
    font-weight: bold;
  }
  .label {
    font-size: 12px;
    font-weight: 500;
  }
  .separator {
    font-size: 24px;
    font-weight: bold;
    margin: 0 10px;
  }
</style>
