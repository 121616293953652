// blocks
// Regular blocks being used in content creation
import Bonus from "$lib/admin/components/blocks/blocks/Bonus.svelte";
import ButtonLink from "$lib/admin/components/blocks/blocks/ButtonLink.svelte";
import Cards from "$lib/admin/components/blocks/blocks/Cards.svelte";
import Content from "$lib/admin/components/blocks/blocks/Content.svelte";
import DataTable from "$lib/admin/components/blocks/blocks/DataTable/DataTable.svelte";
import FAQ from "$lib/admin/components/blocks/blocks/FAQ.svelte";
import HighlightedCasino from "$lib/admin/components/blocks/blocks/HighlightedCasino.svelte";
import TournamentCard from "$lib/admin/components/blocks/blocks/TournamentCard.svelte";
import Image from "$lib/admin/components/blocks/blocks/Image.svelte";
import FocusPanel from "$lib/admin/components/blocks/blocks/FocusPanel.svelte";
import Links from "$lib/admin/components/blocks/blocks/Links.svelte";
import List from "$lib/admin/components/blocks/blocks/List.svelte";
import PostList from "$lib/admin/components/blocks/blocks/PostList.svelte";
import Separator from "$lib/admin/components/blocks/blocks/Separator.svelte";
import Table from "$lib/admin/components/blocks/blocks/Table.svelte";
import TableFacts from "$lib/admin/components/blocks/blocks/TableFacts.svelte";
import TabbedTable from "$lib/admin/components/blocks/blocks/TabbedTable.svelte";
import Toplist from "$lib/admin/components/blocks/blocks/Toplist.svelte";
import Video from "$lib/admin/components/blocks/blocks/Video.svelte";
import Experience from "$lib/admin/components/blocks/blocks/Experience/Experience.svelte";
import Feedback from "$lib/admin/components/blocks/blocks/Feedback.svelte";
import RatingsAndSummary from "$lib/admin/components/blocks/blocks/Ratings/Ratings.svelte";
import RecentChanges from "$lib/admin/components/blocks/blocks/RecentChanges.svelte";
import TopListEntry from "$lib/admin/components/blocks/blocks/TopListEntry.svelte";
import FactsBox from "$lib/admin/components/blocks/blocks/FactsBox.svelte";

// meta
// Mostly helper blocks that are used to accompany user-generated components
import ArticleAd from "$lib/admin/components/blocks/helpers/ArticleAd.svelte";
import LastUpdated from "$lib/admin/components/blocks/helpers/LastUpdated.svelte";
import Placeholder from "$lib/admin/components/blocks/helpers/Placeholder.svelte";

// helpers
// Blocks that are not used directly, but rather indirectly in other blocks
// we do not need to import these; if we do, then they are not exclusively helpers and should be put into another section

// sections
// Blocks that are considered separate from content or are whole sections of content
import FixedData from "$lib/admin/components/blocks/sections/FixedData.svelte";
import FocusPanelContent from "$lib/admin/components/blocks/sections/FocusPanelContent.svelte";
import Intro from "$lib/admin/components/blocks/sections/Intro.svelte";
import IntroLinks from "$lib/admin/components/blocks/sections/IntroLinks.svelte";
import IntroReview from "$lib/admin/components/blocks/sections/IntroReview.svelte";
import LotteryResults from "$lib/admin/components/blocks/sections/LotteryResults.svelte";
import StepByStep from "$lib/admin/components/blocks/sections/StepByStep.svelte";
import StreamTable from "$lib/admin/components/blocks/sections/StreamTable.svelte";
import WriterMeta from "$lib/admin/components/blocks/sections/WriterMeta.svelte";

// tournaments
import UserStatistics from "$lib/admin/components/blocks/blocks/Tournaments/UserStatistics.svelte";
import Winners from "$lib/admin/components/blocks/blocks/Tournaments/Winners.svelte";

// testing
// Components that are close to ready and just being tested

// wip
// Components that are just being started, ideas and generally stuff that are quite new

////
// WORK IN PROGRESS
////

// features
/*
import ContactForm from "$lib/admin/components/blocks/wip/ContactForm.svelte"
import ReviewHero from "$lib/admin/components/blocks/wip/ReviewHero.svelte"
 */

// match
/*
import About from "$lib/admin/components/blocks/wip/match/About.svelte"
import Dream11 from "$lib/admin/components/blocks/wip/match/Dream11.svelte"
import Header from "$lib/admin/components/blocks/wip/match/Header.svelte"
import Info from "$lib/admin/components/blocks/wip/match/Info.svelte"
import Livescore from "$lib/admin/components/blocks/wip/match/Livescore.svelte"
import Odds from "$lib/admin/components/blocks/wip/match/Odds.svelte"
import Teampreviews from "$lib/admin/components/blocks/wip/match/Teampreviews.svelte"
*/

// tournament
/*
import IntroTournament from "$lib/admin/components/blocks/wip/tournament/IntroTournament.svelte"
import LatestPredictions from "$lib/admin/components/blocks/wip/tournament/LatestPredictions.svelte"
import PointsTable from "$lib/admin/components/blocks/wip/tournament/PointsTable.svelte"
*/

// sections
/*
import ArticleEventHeader from "$lib/admin/components/blocks/sections/ArticleEventHeader.svelte"
import ArticleMeta from "$lib/admin/components/blocks/sections/ArticleMeta.svelte"
import Guide from "$lib/admin/components/blocks/sections/Guide.svelte"
import IntroArticle from "$lib/admin/components/blocks/sections/IntroArticle.svelte"
import IntroLinks from "$lib/admin/components/blocks/sections/IntroLinks.svelte"
import SideBySide from "$lib/admin/components/blocks/sections/SideBySide.svelte"
import ToplistSection from "$lib/admin/components/blocks/sections/ToplistSection.svelte"
 */

// testing
/*
import Banner from "$lib/admin/components/blocks/testing/Banner.svelte"
import BettingTips from "$lib/admin/components/blocks/testing/BettingTips.svelte"
import CricketBettingTipsSingle from "$lib/admin/components/blocks/testing/CricketBettingTipsSingle.svelte"
import MatchCenter from "$lib/admin/components/blocks/testing/MatchCenter.svelte"
import Newsletter from "$lib/admin/components/blocks/testing/Newsletter.svelte"
import OddsList from "$lib/admin/components/blocks/testing/OddsList.svelte"
import PredictionsList from "$lib/admin/components/blocks/testing/PredictionsList.svelte"
import ReviewTemplate from "$lib/admin/components/blocks/testing/ReviewTemplate.svelte"
 */

const imports = {
  /* BLOCKS */
  Bonus: { cls: Bonus, folder: "Blocks" },
  ButtonLink: { cls: ButtonLink, folder: "Blocks" },
  Cards: { cls: Cards, folder: "Blocks" },
  Content: { cls: Content, folder: "Blocks", extraOptions: ["Add to TOC"] },
  FAQ: {
    cls: FAQ,
    folder: "Blocks",
    extraOptions: ["Heading", "Section Styling", "Add to TOC"],
  },
  FocusPanel: {
    cls: FocusPanel,
    folder: "Blocks",
    extraOptions: ["Add to TOC"],
  },
  HighlightedCasino: { cls: HighlightedCasino, folder: "Blocks" },
  Image: { cls: Image, folder: "Blocks", extraOptions: ["Add to TOC"] },
  Links: { cls: Links, folder: "Blocks" },
  List: {
    cls: List,
    folder: "Blocks",
    extraOptions: ["Heading", "Add to TOC"],
  },
  PostList: {
    cls: PostList,
    folder: "Blocks",
    extraOptions: [
      "Heading",
      "Read More Link",
      "Add Pagination",
      "Section Styling",
      "Add to TOC",
    ],
  },
  Separator: { cls: Separator, folder: "Blocks" },
  TabbedTable: { cls: TabbedTable, folder: "Blocks" },
  Table: {
    cls: Table,
    folder: "Blocks",
    extraOptions: ["Heading", "Add to TOC"],
  },
  TableFacts: {
    cls: TableFacts,
    folder: "Blocks",
    extraOptions: ["Add to TOC"],
  },
  Toplist: {
    cls: Toplist,
    folder: "Blocks",
    extraOptions: [
      "Heading",
      "Toplist Navigation Bar",
      "Toplist Load More",
      "Toplist Link Button",
      "Toplist Button",
      "Add to TOC",
      "Toplist Fixed Content",
    ],
  },
  Video: { cls: Video, folder: "Blocks" },
  Experience: { cls: Experience, folder: "Blocks" },
  "Ratings + Summary": { cls: RatingsAndSummary, folder: "Blocks" },
  Feedback: { cls: Feedback, folder: "Blocks" },
  RecentChanges: { cls: RecentChanges, folder: "Blocks" },
  TopListEntry: { cls: TopListEntry, folder: "Blocks" },
  FactsBox: { cls: FactsBox, folder: "Blocks" },

  ArticleAd: { cls: ArticleAd, folder: "Meta" },
  LastUpdated: { cls: LastUpdated, folder: "Meta" },
  Placeholder: { cls: Placeholder, folder: "Meta" },

  /* SECTIONS */
  FixedData: { cls: FixedData, folder: "Sections", extraOptions: ["Heading"] },
  FocusPanelContent: { cls: FocusPanelContent, folder: "Sections" },
  Intro: {
    cls: Intro,
    folder: "Sections",
    extraOptions: ["Hide TOC", "Add to TOC"],
  },
  IntroLinks: { cls: IntroLinks, folder: "Sections" },
  IntroReview: {
    cls: IntroReview,
    folder: "Sections",
    extraOptions: ["Hide TOC", "Add to TOC"],
  },
  LotteryResults: {
    cls: LotteryResults,
    folder: "Sections",
    extraOptions: ["Heading", "Read More Link", "Add to TOC"],
  },
  StepByStep: {
    cls: StepByStep,
    folder: "Sections",
    extraOptions: ["Heading", "CTA Link", "Add to TOC"],
  },
  StreamTable: {
    cls: StreamTable,
    folder: "Sections",
    extraOptions: ["Heading"],
  },
  WriterMeta: {
    cls: WriterMeta,
    folder: "Sections",
    extraOptions: ["Writer Title"],
  },

  /* Tournaments */
  Winners: { cls: Winners, folder: "Tournaments" },
  UserStatistics: { cls: UserStatistics, folder: "Tournaments" },
  TournamentCard: { cls: TournamentCard, folder: "Tournaments" },
  DataTable: { cls: DataTable, folder: "Tournaments" },

  /* PREVIOUS COMPONENTS; DELETED AND REMOVED */
  /*
  'ContactForm': {cls: ContactForm, folder: "Features"},
  'ReviewHero': {cls: ReviewHero, folder: "Features"},

  'About': {cls: About, folder: "Match"},
  'Dream11': {cls: Dream11, folder: "Match"},
  'Header': {cls: Header, folder: "Match"},
  'Info': {cls: Info, folder: "Match"},
  'Livescore': {cls: Livescore, folder: "Match"},
  'Odds': {cls: Odds, folder: "Match"},
  'Teampreviews': {cls: Teampreviews, folder: "Match"},

  'MatchCenter': {cls: MatchCenter, folder: "Sections"},
  'BettingTips': {cls: BettingTips, folder: "Sections"},

  'IntroTournament': {cls: IntroTournament, folder: "Tournament"},
  'LatestPrediction': {cls: LatestPredictions, folder: "Tournament"},
  'PointsTable': {cls: PointsTable, folder: "Tournament"},

  'ArticleEventHeader': {cls: ArticleEventHeader, folder: "Sections"},
  'ArticleMeta': {cls: ArticleMeta, folder: "Sections"},
  'Guide': {cls: Guide, folder: "Sections"},
  'IntroArticle': {cls: IntroArticle, folder: "Sections"},
  'IntroLinks': {cls: IntroLinks, folder: "Sections"},

  'Newsletter': {cls: Newsletter, folder: "Testing"},
  'ReviewTemplate': {cls: ReviewTemplate, folder: "Testing"},

  'Banner': {cls: Banner, folder: "Testing"},
  'CricketBettingTipsSingle': {cls: CricketBettingTipsSingle, folder: "Testing"},
  'OddsList': {cls: OddsList, folder: "Testing"},
  'PredictionsList': {cls: PredictionsList, folder: "Testing"},
*/
};

export default imports;
