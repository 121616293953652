<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import Heading from "$lib/admin/components/blocks/helpers/Heading.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import { faq } from "$lib/data/mappers/structured-data";
  import { isTournamentsRoute } from "$lib/services/utils";
  import { page } from "$app/stores";
  import { project } from "$lib/services/store";
  import Icons from "$lib/main/components/Icons.svelte";

  type FAQContent = {
    header?: string;
    content?: string;
  };

  const tournamentRoute = $project.settings.tournamentUrl ?? "tournaments";

  export let props: { content: FAQContent[]; sectionStyling?: string } | null =
    null;
  export let adminView = false;

  const dispatch = createEventDispatcher();
  let selectedFaq = 0;

  if (!props) {
    props = { content: [] };
  }
  if (!props.content) {
    props.content = [{}];
  }
  let hiddenItems: boolean[] = [];

  $: if (props.content && hiddenItems.length !== props.content.length) {
    hiddenItems = props.content.map(() => true);
  }

  function toggle(index: number): void {
    hiddenItems[index] = !hiddenItems[index];
  }
</script>

<svelte:head>
  {#if !adminView && props?.content?.length}
    {@html faq(props.content)}
  {/if}
</svelte:head>

{#if adminView}
  {#if props?.content}
    {#each props.content as item, idx}
      {#if idx === selectedFaq}
        <Group>
          <Group colspan="4-8" addClass="form-item">
            <Input
              label="Question"
              placeholder="Question"
              bind:value={item.header}
              on:keyup
            />
          </Group>
          <Group addClass="form-item">
            <div class={`faq-question-${idx}`}></div>
            <SunEditorForm bind:text={item.content} on:keyup />
          </Group>
        </Group>
      {/if}
    {/each}
    <Group colspan="1-auto" addClass="form-item">
      <Group colspan="1-auto-auto" addClass="narrow">
        <div class="multi-meta">
          Showing FAQ {selectedFaq + 1} of {props.content.length} FAQs
        </div>
        <Button
          on:click={() => {
            if (selectedFaq > 0) selectedFaq -= 1;
            else selectedFaq = props.content.length - 1;
          }}>‹</Button
        >
        <Button
          on:click={() => {
            selectedFaq += 1;
            if (selectedFaq >= props.content.length) selectedFaq = 0;
          }}>›</Button
        >
      </Group>
      <Group addClass="inline narrow">
        <Button
          addClass="secondary"
          title="Remove this question"
          onlyIcon
          icon="trash-2"
          on:click={() => {
            props.content = props.content.filter((_, i) => i !== selectedFaq);
            dispatch("keyup");
          }}>Remove</Button
        >
        <Button
          addClass="primary"
          title="Add a question"
          onlyIcon
          icon="plus-square"
          on:click={() => {
            props.content.push({});
            dispatch("keyup");
          }}>Add</Button
        >
      </Group>
    </Group>
  {/if}
{:else}
  <Block
    blockClass={"block block-faq" +
      (props?.sectionStyling ? " " + props.sectionStyling : "")}
    {props}
  >
    <Heading {props} />
    {#if props?.content}
      {#each props.content as faq, index}
        <div
          class="accordion"
          class:dark-mode={isTournamentsRoute(
            $page.url.pathname,
            tournamentRoute
          )}
        >
          {#if faq.header}
            <h4
              class="question"
              aria-expanded={!hiddenItems[index]}
              on:click={() => toggle(index)}
            >
              {faq.header}
              {#if isTournamentsRoute($page.url.pathname, tournamentRoute)}
                {#if !hiddenItems[index]}
                  <Icons name="minus-accordion" />
                {:else}
                  <Icons name="plus-accordion" />
                {/if}
              {/if}
            </h4>
          {/if}
          {#if faq.content}
            <div class="answer" hidden={hiddenItems[index]}>
              {@html faq.content}
            </div>
          {/if}
        </div>
        {#if !faq.header && !faq.content}
          <span class="content">No FAQs added yet..</span>
        {/if}
      {:else}
        <p>No FAQ created..</p>
      {/each}
    {:else}
      <p>No FAQ created..</p>
    {/if}
  </Block>
{/if}

<style>
  .multi-meta {
    font-size: 10px;
    display: grid;
    align-items: center;
  }
  .dark-mode .question {
    display: flex;
    background-color: #121212;
    color: #fff;
    justify-content: space-between;
  }
  .dark-mode .answer {
    background-color: #000;
  }
  :global(.dark-mode .answer p span[style]) {
    color: #fff !important;
    background-color: transparent !important;
  }
</style>
