<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { project, previewActiveModal } from "$lib/services/store";
  import supabase from "$lib/services/db";
  import Cards from "$lib/admin/components/Cards.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Modal from "$lib/main/components/Modal.svelte";
  import Manage from "$lib/admin/components/Manage.svelte";
  import ImageBrowser from "$lib/admin/components/media/ImageBrowser.svelte";
  import { getBucketLink } from "$lib/common/helpers/image.ts";
  import { ImageFolderEnum } from "$lib/common/enums/imageFolderOptions.ts";

  interface Message {
    success: boolean;
    display: string;
  }

  export let imageUrl: string = "";
  export let folder: string = "media";

  const dispatch = createEventDispatcher();
  const IMG_URL_FOLDER: string = `${getBucketLink()}${folder}/`;
  const defaultImagePath = `${$project.key}/images/${folder}/`;

  let toggleUploadForm: boolean = false;
  let toggleBrowseForm: boolean = false;
  let uploading: boolean = false;
  let message: Message | null = null;
  let files: File[] | null;
  let uploadedFile: File | null = null;
  let imagePath: string = defaultImagePath;
  let imageName: string = imageUrl;
  let loadedImageUrl: string = '';

  $: computedImageSrc = imageName ? `${IMG_URL_FOLDER}${imageName}` : "";

  console.log(imageName)

  const handleFileChange = (): void => {
    message = null;

    const file = files && files[0];

    if (file) {
      imageName = file.name;
      uploadedFile = file;
      loadedImageUrl = URL.createObjectURL(file);
      toggleUploadForm = true;
    }
  };

  const handleUploadError = (uploadError: any) => {
    console.error(uploadError);
    if (uploadError.error === "Duplicate") {
      uploadError.message += "\nTry changing the image name.";
    }
    message = { success: false, display: uploadError.message };
  };

  const uploadImage = async () => {
    try {
      uploading = true;
      message = null;
      if (!uploadedFile) return;

      const filePath = `${imagePath}${imageName}`;
      const { error: uploadError } = await supabase.storage
        .from("content")
        .upload(filePath, uploadedFile, {
          cacheControl: "0",
          upsert: folder === ImageFolderEnum.OPERATORS,
        });

      if (uploadError) {
        handleUploadError(uploadError);
        return;
      }

      message = { success: true, display: "Image uploaded successfully!" };
      imagePath = filePath;
      loadedImageUrl = '';
      dispatch("upload", { updatedImageUrl: imageName });

      // Close Modal form only when successful, maybe image name was already
      // taken, so needs to keep open so user can change it.
      toggleUploadForm = false;
    } catch (error) {
      message = { success: false, display: error.message };
    } finally {
      uploading = false;
    }
  };

  const removeImage = () => {
    imageName = "";
    loadedImageUrl = '';
    files = null;
    uploadedFile = null;
    imagePath = defaultImagePath;
  };
</script>

<!-- Image display if uploaded -->
<Group addClass="image-upload">
  <div class="image-holder flex-c-c">
    {#if imageName}
      <img
        loading="lazy"
        data-src={loadedImageUrl || computedImageSrc}
        src={loadedImageUrl || computedImageSrc}
      />
      <Button
        title="Remove current image"
        onlyIcon
        icon="trash-2"
        on:click={removeImage}
      />
      <span class="image-url">{computedImageSrc}</span>
    {:else}
      <span>No image found. Upload or browse for an image.</span>
    {/if}
  </div>
</Group>

<!-- Image already uploaded, can only adjust alt text and caption -->
{#if !imageName}
  <Group>
    <Button
      on:click={() => {
        toggleBrowseForm = true;
        $previewActiveModal = true;
      }}>Browse</Button
    >
    <!-- TODO: add 'accept="image/*"' to Input component? -->
    <Input
      id="upload-image"
      type="file"
      placeholder="Upload image"
      bind:files
      on:change={handleFileChange}
      disabled={uploading}
    />
  </Group>
{/if}

<!-- Modal form to set initial image, alt and caption -->
<Modal bind:toggleModal={toggleUploadForm}>
  <svelte:fragment slot="header">Image details</svelte:fragment>
  <Cards type="form">
    <Group colspan="4-8">
      <Input
        label="Image name"
        placeholder="Image name"
        id="image-name"
        type="text"
        bind:value={imageName}
      />
    </Group>
    <Group addClass="form-item inline">
      <Manage {message} />
      <Button on:click={uploadImage} addClass="secondary" icon="plus-square">Upload</Button>
    </Group>
  </Cards>
</Modal>

<!-- Modal for browsing and selecting from existing images -->
<Modal bind:toggleModal={toggleBrowseForm}>
  <svelte:fragment slot="header">Browse Images</svelte:fragment>
  <ImageBrowser
    allowDeletes={false}
    imageSelectorCallback={(img) => {
      imageName = img.name;
      dispatch("upload", { updatedImageUrl: img.name });
    }}
  />
</Modal>

<style>
  .image-holder {
    background-color: var(--bg-clr);
    border-radius: var(--bd-rad);
    padding: 1rem;
    position: relative;
    display: block;
  }
  .image-holder img {
    max-height: 10rem;
    max-width: 100%;
    object-fit: contain;
    border-radius: 4px;
    margin: 0 auto;
  }
  .image-holder span.image-url {
    margin: 0;
    font-size: 0.5rem;
    color: #999;
    text-align: center;
    display: block;
    word-wrap: break-word;
    overflow: hidden;
  }
  .image-holder span {
    margin: 1rem;
  }
  :global(.image-holder button) {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
</style>
