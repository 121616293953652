<script lang="ts">
  import { slide } from "svelte/transition";
  import { createEventDispatcher } from "svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Skeleton from "$lib/main/components/Skeleton.svelte";
  import { formatUnixDate } from "$lib/common/helpers/date";
  import type { Tab } from "@/src/types/components/DataTable";

  export let tabs: Tab[];
  export let hideHeader: boolean;
  export let accordionData: Record<number, any[]>;

  let skeletons = Array(5).fill({});

  const dispatch = createEventDispatcher();

  function toggleAccordion(index: number) {
    tabs[index].isOpen = !tabs[index].isOpen;
    if (tabs[index].isOpen && !accordionData[index]) {
      dispatch("fetchData", index);
    }
    tabs = [...tabs];
  }

  function filterData(row: any, currentTab: Tab) {
    const columns = Array.isArray(currentTab.selectedColumns)
      ? currentTab.selectedColumns
      : [];

    for (const col of columns) {
      if (
        col.key in row &&
        typeof row[col.key] === "boolean" &&
        col.booleanValue !== "any" &&
        col.booleanValue !== undefined
      ) {
        if (row[col.key] !== col.booleanValue) {
          return false;
        }
      }
    }
    return true;
  }

  function getEndpointClass(endpoint?: string): string {
    return endpoint ? endpoint.toLowerCase() : "";
  }
</script>

<div class="accordion">
  {#each tabs as tab, index}
    <div class="accordion-item">
      <div
        class="accordion-header"
        role="button"
        tabindex="0"
        on:click={() => toggleAccordion(index)}
        on:keydown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            toggleAccordion(index);
          }
        }}
      >
        {#if getEndpointClass(tab.endpoint) === "leaderboard"}
          <Icons
            name="trophy"
            fill="#FFDD4D"
            strokeWidth="0"
            width="20"
            height="20"
          />
        {/if}
        <div class="accordion-header-title">{tab.title}</div>
        <div class="icon">
          {#if tab.isOpen}
            <Icons
              name="chevron-up"
              strokeWidth="0"
              fill="#fff"
              width="20"
              height="20"
            />
          {:else}
            <Icons
              name="chevron-down"
              strokeWidth="0"
              fill="#fff"
              width="20"
              height="20"
            />
          {/if}
        </div>
      </div>
      {#if tab.isOpen}
        <div
          class="accordion-content {getEndpointClass(tab.endpoint)}"
          transition:slide={{ duration: 300 }}
        >
          <table class="table-content {getEndpointClass(tab.endpoint)}">
            {#if accordionData[index] && accordionData[index].length > 0}
              {#if !hideHeader}
                <thead>
                  <tr class="row header">
                    {#each tab.selectedColumns.filter((col) => !col.hidden) as column}
                      <th>{column.displayName}</th>
                    {/each}
                  </tr>
                </thead>
              {/if}
              <tbody>
                {#each accordionData[index].filter( (row) => filterData(row, tab) ) as row}
                  <tr class="row">
                    {#each tab.selectedColumns.filter((col) => !col.hidden) as column}
                      <td>
                        {#if column.key === "logoImage" && "backgroundImage" in row}
                          <div class="logo-container">
                            <img
                              class="background-image"
                              src={row.backgroundImage}
                              referrerpolicy="no-referrer"
                              alt="Background"
                            />
                            <img
                              src={row.logoImage}
                              referrerPolicy="no-referrer"
                              alt="Logo"
                              class="logo-image"
                            />
                          </div>
                        {:else if column.key in row}
                          {#if typeof row[column.key] === "boolean"}
                            {row[column.key] ? "True" : "False"}
                          {:else if column.key === "startDate" || column.key === "endDate"}
                            {formatUnixDate(row[column.key])}
                          {:else}
                            {row[column.key] !== undefined
                              ? row[column.key]
                              : "N/A"}
                          {/if}
                        {:else}
                          N/A
                        {/if}
                      </td>
                    {/each}
                  </tr>
                {/each}
              </tbody>
            {:else}
              <tbody>
                {#each skeletons as _, i}
                  <tr>
                    <td
                      colspan={tab.selectedColumns.filter((col) => !col.hidden)
                        .length}
                    >
                      <Skeleton
                        maxWidth="100%"
                        height="20px"
                        baseColor="rgb(23, 23, 23)"
                        highlightColor="rgb(38, 38, 38)"
                        customStyles="margin-bottom: 10px;"
                      />
                    </td>
                  </tr>
                {/each}
              </tbody>
            {/if}
          </table>
        </div>
      {/if}
    </div>
  {/each}
</div>

<style>
  .accordion {
    width: 100%;
    border-radius: 7px;
    overflow: hidden;
  }

  .accordion-item {
    border-radius: 7px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #121212;
    color: #fff;
    cursor: pointer;
  }

  .accordion-header-title {
    margin-left: 9px;
  }

  .accordion-header .icon {
    margin-left: auto;
  }

  .accordion-content {
    background-color: #282828;
  }

  .accordion-content .header th {
    background-color: transparent;
  }

  .table-content {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;
  }

  .table-content .row th,
  .table-content .row td {
    padding: 4px;
    text-align: left;
    background-color: #3f3f3f;
    color: #fff;
  }

  .table-content .row th {
    background-color: transparent;
  }

  .logo-container {
    position: relative;
    width: 170px;
    height: 62px;
    overflow: hidden;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 95%;
    object-fit: contain;
    z-index: 2;
  }
</style>
