<script lang="ts">
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import { orgOperators, project, user } from "$lib/services/store";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import ToplistRow from "$lib/admin/components/blocks/helpers/ToplistRow.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import { getOrdinal } from "$lib/common/helpers/ordinalFormat";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import {
    fetchLiveTournaments,
    fetchRunningTournament,
  } from "$lib/services/tournaments";
  import type { Tournament } from "@/src/types/tournaments";

  interface UserStatisticsProps {
    tournamentId: number;
    tournamentName: string;
    operatorId: number | null;
    toplistFocus: string;
    toplistPaymentMethodProvider: string;
  }

  export let operatorId: number;

  export let adminView = false;
  export let props: UserStatisticsProps = {
    tournamentName: "",
    tournamentId: 0,
    operatorId: 0,
    toplistFocus: "tournament-sponsor",
    toplistPaymentMethodProvider: "",
  };

  // Props that are passed when invoking manually
  export let tournamentId: number;
  export let tournamentName: string;
  export let operator: Operator | undefined;
  export let username = "";

  let tournaments: Tournament[] = [];
  let selectedTournamentData = writable<any>(null);

  async function handleTournamentChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const selectedTournamentId = parseInt(target.value, 10);
    const selectedTournament = tournaments.find(
      (t) => t.tournamentId === selectedTournamentId
    );
    if (selectedTournament) {
      props.tournamentId = selectedTournament.tournamentId;
      props.tournamentName = selectedTournament.name;
      await fetchAndSetTournamentData(selectedTournamentId);
    }
  }

  onMount(async () => {
    try {
      const tournamentData = await fetchLiveTournaments();
      tournaments = tournamentData;
      if (props.tournamentId || tournamentId) {
        await fetchAndSetTournamentData(props.tournamentId || tournamentId);
      }
    } catch (error) {
      console.error("Error fetching live tournaments:", error);
    }
  });

  async function fetchAndSetTournamentData(tournamentId: number) {
    try {
      const data = await fetchRunningTournament(tournamentId, username);
      selectedTournamentData.set(data);
    } catch (error) {
      console.error("Error fetching running tournament data:", error);
      selectedTournamentData.set(null);
    }
  }

  const lang = $project.settings.lang ?? "en";
  const operators: Operator[] = $orgOperators;

  // We might not need this anymore, refactor incoming
  $: operator = operators?.find(
    (x) => x.id === Number(props.operatorId || operatorId)
  );
</script>

{#if adminView}
  <Group colspan="4-8">
    <Input
      label="Operator"
      type="select"
      bind:value={props.operatorId}
      on:change
    >
      <option value="0">~not set~</option>
      {#each operators as operator}
        <option value={operator.id}
          >{operator.name ?? "Couldn't find metadata"}</option
        >
      {/each}
    </Input>
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input
      type="select"
      label="Toplist topic"
      on:change
      bind:value={props.toplistFocus}
    >
      <option value="tournament-sponsor">Tournament Sponsor</option>
    </Input>
  </Group>
  <Group addClass="form-item">
    <select
      on:change={handleTournamentChange}
      bind:value={props.tournamentId}
      required
    >
      <option value="" disabled selected>Select a tournament</option>
      {#each tournaments as tournament}
        <option value={tournament.tournamentId}>{tournament.name}</option>
      {/each}
    </select>
  </Group>
{:else}
  <div class="">
    <!-- <a class="back-button" href="/">
      <Icons name="arrow-left" color="#CC3129"></Icons>
      <Translate text="back" />
    </a> -->
    <div class="statistics">
      <div class="tournament-info">
        <h1 class="tournament-name">
          {$selectedTournamentData?.name || tournamentName}
        </h1>
        <span class="offered-by">Offered by {operator?.name}</span>
        <div class={`toplist ${props.toplistFocus}`} class:show-full={true}>
          <ToplistRow {props} {operator} toplistPosition={1} />
        </div>
      </div>
      <div class="user-statistics">
        <h2 class="tournament-stats-label stats-title">
          <Translate text="your stats" />
        </h2>
        <div class="box score">
          <div class="title">Score</div>
          <div class="value">
            {$selectedTournamentData?.playerScoreNoPrefix || "-"}
          </div>
        </div>
        <div class="box rank">
          <div class="title">Rank</div>
          <div class="value">
            {getOrdinal($selectedTournamentData?.userPosition, lang)}
          </div>
        </div>
        <div class="box spins">
          <div class="title">Spins</div>
          <div class="value">
            {$selectedTournamentData?.spinsLeft || "-"} / {$selectedTournamentData?.spinsCount ||
              "-"}
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .container {
    background-color: #282828;
    color: #fff;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .back-button {
    font-weight: 300;
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    width: fit-content;
  }
  .statistics {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 0px 0px;
    grid-template-areas:
      "tournament-info"
      "user-statistics";
  }

  @media only screen and (min-width: 991px) {
    .statistics {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 20px;
      grid-template-areas: "tournament-info user-statistics";
    }
  }
  .tournament-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 36px;
    gap: 0px 0px;
    grid-template-areas:
      "."
      "offered-by";
    grid-area: tournament-info;
  }

  .user-statistics {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 60px 60px;
    gap: 0px 10px;
    grid-template-areas:
      "stats-title stats-title stats-title"
      "score rank spins";
    grid-area: user-statistics;
  }

  @media only screen and (min-width: 991px) {
    .user-statistics {
      grid-template-rows: 60px 80px;
    }
  }

  .stats-title {
    grid-area: stats-title;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }
  .rank {
    justify-self: center;
    grid-area: rank;
    width: 100px;
  }
  .score {
    justify-self: center;
    grid-area: score;
    width: 100px;
  }
  .spins {
    justify-self: center;
    grid-area: spins;
    width: 100px;
  }
  .tournament-name {
    margin: 0;
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
  }
  .offered-by {
    font-size: 16px;
    font-weight: 300;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #3f3f3f;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #575757;
    padding: 8px;
    font-size: 16px;
  }

  .box .title {
    font-size: 16px;
    font-weight: 400;
  }

  .box .value {
    color: #ffcf3a;
  }
</style>
