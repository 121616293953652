import { translations } from "../../data/translations";

export function getTimeDifferenceText(timestamp: string, lang: string): string {
  const t = translations as any;
  const currentTimestamp: Date = new Date();
  const givenTimestamp: Date = new Date(timestamp);

  const timeDifference: number =
    currentTimestamp.getTime() - givenTimestamp.getTime();
  const secondsDifference: number = Math.floor(timeDifference / 1000);
  const minutesDifference: number = Math.floor(secondsDifference / 60);
  const hoursDifference: number = Math.floor(minutesDifference / 60);
  const daysDifference: number = Math.floor(hoursDifference / 24);

  if (daysDifference >= 1) {
    return `${daysDifference} ${t["days ago"][lang]}`;
  } else if (hoursDifference >= 1) {
    return `${hoursDifference} ${t["hours ago"][lang]}`;
  } else if (minutesDifference >= 1) {
    return `${minutesDifference} ${t["minutes ago"][lang]}`;
  } else {
    return t["Just now"][lang];
  }
}

export const formatDate = (initialDate: string): string => {
  const dateObj = new Date(initialDate);
  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export function formatUnixDate(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}
