import {
  PUBLIC_SOCIAL_TOURNAMENT_URL,
  PUBLIC_SOCIAL_TOURNAMENT_LICENSE,
  PUBLIC_SOCIAL_TOURNAMENT_JWT_SECRET,
} from "$env/static/public";
import { SignJWT } from "jose";
import type { Tournament, LeaderboardResponse } from "@/src/types/tournaments";

const getJWTToken = async (): Promise<string> => {
  const secretKey = new TextEncoder().encode(
    PUBLIC_SOCIAL_TOURNAMENT_JWT_SECRET
  );
  const jwt = await new SignJWT({
    exp: Math.floor(Date.now() / 1000) + 60 * 60,
  })
    .setProtectedHeader({ alg: "HS256" })
    .sign(secretKey);
  return jwt;
};

const customFetch = async (
  url: string,
  method: string,
  body: any = null,
  additionalHeaders: Record<string, string> = {}
) => {
  const token = await getJWTToken();
  const headers = {
    "Content-Type": "application/json",
    license: PUBLIC_SOCIAL_TOURNAMENT_LICENSE,
    Authorization: `${token}`,
    ...additionalHeaders,
  };
  const options = { method, headers, body: body ? JSON.stringify(body) : null };
  const response = await fetch(url, options);
  const json = await response.json();
  if (!response.ok) {
    throw new Error(json.message || "Failed to fetch data");
  }
  return json;
};

const encodeQueryParams = (params: Record<string, any>): string => {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
};

export const fetchTournaments = async (): Promise<Tournament[]> => {
  const response = await customFetch(
    PUBLIC_SOCIAL_TOURNAMENT_URL + "/getTournamentFullSchedule",
    "GET"
  );
  return response.data;
};

export const fetchLiveTournaments = async (): Promise<Tournament[]> => {
  const response = await customFetch(
    PUBLIC_SOCIAL_TOURNAMENT_URL + "/getLiveTournaments",
    "GET"
  );
  return response.data;
};

export const fetchTournamentResults = async (): Promise<Tournament[]> => {
  const response = await customFetch(
    PUBLIC_SOCIAL_TOURNAMENT_URL + "/getTournamentResults",
    "GET"
  );
  return response.data;
};

export const fetchTournamentLeaderBoard = async (
  tournament_id: number
): Promise<LeaderboardResponse> => {
  const queryParams = encodeQueryParams({ tournament_id });
  const response = await customFetch(
    `${PUBLIC_SOCIAL_TOURNAMENT_URL}/getTournamentLeaderBoard?${queryParams}`,
    "GET"
  );
  return response.data.scoreboard;
};

export const fetchFeatureTournaments = async (
  tournamentIdList: number
): Promise<Tournament[]> => {
  const queryParams = encodeQueryParams({ tournamentIdList });
  const response = await customFetch(
    `${PUBLIC_SOCIAL_TOURNAMENT_URL}/getFeatureTournaments?${queryParams}`,
    "GET"
  );
  return response.data;
};

export const fetchPlayerTournamentHistory = async (
  username: string
): Promise<any> => {
  const queryParams = encodeQueryParams({ username });
  const response = await customFetch(
    `${PUBLIC_SOCIAL_TOURNAMENT_URL}/getPlayerTournamentHistory?${queryParams}`,
    "GET",
    null,
    { username }
  );
  return response.data;
};

export const fetchRunningTournament = async (
  tournamentId: number,
  username: string
): Promise<any> => {
  const queryParams = encodeQueryParams({ username });
  const response = await customFetch(
    `${PUBLIC_SOCIAL_TOURNAMENT_URL}/getRunningTournament/${tournamentId}?${queryParams}`,
    "GET",
    null,
    { username }
  );
  return response.data;
};

export const startTournamentGame = async (
  tournament_id: number,
  username: string
): Promise<any> => {
  const body = {
    tournament_id,
    action: "join",
  };

  const response = await customFetch(
    `${PUBLIC_SOCIAL_TOURNAMENT_URL}/startGame`,
    "POST",
    body,
    { username }
  );

  return response;
};
