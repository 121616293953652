<script lang="ts">
  import { onMount } from "svelte";
  import { writable, derived } from "svelte/store";
  import type { Writable } from "svelte/store";
  import { orgGameProviders } from "$lib/services/store";
  import { createEventDispatcher } from "svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import CountdownTimer from "./CountdownTimer.svelte";
  import { fetchTournaments } from "$lib/services/tournaments";
  import type { Tournament, PropTournament } from "@/src/types/tournaments";
  import type { GameProvider } from "@/src/types/GameProvider";
  import Translate from "../helpers/Translate.svelte";

  export let adminView: boolean;
  export let props: {
    tournaments: PropTournament[];
  } = { tournaments: [] };

  const dispatch = createEventDispatcher();
  const tournaments = writable<Tournament[]>([]);
  const propsTournaments = writable(props?.tournaments ?? []);
  const typedOrgGameProviders: Writable<GameProvider[]> = orgGameProviders;

  $: propsTournaments.set(props.tournaments);
  $: props.tournaments = $propsTournaments;

  const filteredAndMappedTournaments = derived<
    [typeof tournaments, typeof propsTournaments, typeof typedOrgGameProviders],
    (PropTournament & Tournament & { gameProviderName: string })[]
  >(
    [tournaments, propsTournaments, typedOrgGameProviders],
    ([$tournaments, $propsTournaments, $gameProviders]) => {
      return ($propsTournaments ?? [])
        .filter(
          (item) => item?.tournamentId !== null && item?.gameProvider !== null
        )
        .map((item) => {
          const tournament = $tournaments.find(
            (t) => t.tournamentId === item.tournamentId
          );
          const gameProvider = $gameProviders?.find(
            (gp) => gp.id === item.gameProvider
          );
          return tournament
            ? {
                ...item,
                ...tournament,
                gameProviderName: gameProvider
                  ? gameProvider.name
                  : "Unknown Provider",
              }
            : null;
        })
        .filter(
          (
            item
          ): item is PropTournament &
            Tournament & { gameProviderName: string } => item !== null
        );
    }
  );

  function addTournament(): void {
    propsTournaments.update((tournaments) => [
      ...tournaments,
      { tournamentId: null, gameProvider: null },
    ]);
  }

  function removeTournament(index: number): void {
    propsTournaments.update((tournaments) =>
      tournaments.filter((_, i) => i !== index)
    );
  }

  function flipCard(index: number): void {
    propsTournaments.update((tournaments) =>
      tournaments.map((t, i) =>
        i === index ? { ...t, flipped: !t.flipped } : t
      )
    );
  }

  onMount(() => {
    fetchTournaments().then(
      (data) => tournaments.set(data),
      (error) => console.error("Failed to fetch tournaments:", error)
    );
  });
</script>

{#if adminView}
  {#each $propsTournaments as item, index (index)}
    <div class="admin-tournament-wrapper">
      Tournament #{index + 1}
      <Group colspan="4-8" addClass="form-item">
        <div style="display: flex; align-items: center; font-size: 14px;">
          Event
        </div>
        <select
          bind:value={item.tournamentId}
          on:change={() => dispatch("tournamentSelected", item)}
        >
          <option value="" disabled>Select a tournament</option>
          {#each $tournaments as tournament}
            <option value={tournament.tournamentId}
              >{tournament.name} (ID: {tournament.tournamentId})</option
            >
          {/each}
        </select>
      </Group>
      <Group colspan="4-8" addClass="form-item">
        <div style="display: flex; align-items: center; font-size: 14px;">
          Game Provider
        </div>
        <select
          bind:value={item.gameProvider}
          on:change={() => dispatch("gameProviderSelected", item)}
          required
        >
          <option value="" disabled>Select a Game Provider</option>
          {#each $typedOrgGameProviders as gameProvider}
            <option value={gameProvider.id}>
              {gameProvider.name || "Couldn't find casino game name"}
            </option>
          {/each}
        </select>
      </Group>
      <Button
        addClass="secondary"
        icon="trash-2"
        on:click={() => removeTournament(index)}>Remove</Button
      >
    </div>
  {/each}
  <Button addClass="primary" icon="plus-square" on:click={addTournament}
    >Add tournament</Button
  >
{:else}
  <div class="container">
    <div class="tournaments-container">
      {#each $filteredAndMappedTournaments as item, index (index)}
        <div class="tournament-card {item.flipped ? 'flipped' : ''}">
          <div class="front">
            <div class="visual">
              <img
                src={item.backgroundImage}
                alt={item.name}
                referrerpolicy="no-referrer"
                class="visual-bg"
              />
              <div
                class="gradient-overlay {item.tournamentLabel
                  ? 'rosa'
                  : 'azul'}"
              ></div>
            </div>
            <div class="card-content">
              <span class="tournament-label">{item.tournamentLabel}</span>
              <h2 class="tournament-name">{item.name}</h2>
              <span class="tournament-prize">
                <Icons
                  name="trophy"
                  stroke={"#fff"}
                  strokeWidth="0"
                  width="18"
                  height="18"
                />
                <span>
                  <Translate text="Prize" />
                  {item.prizePool || "N/A"}
                </span>
              </span>
              <img
                src={item.logoImage}
                alt={item.name}
                referrerpolicy="no-referrer"
                class="visual-logo"
              />

              {#if item.isLive}
                <CountdownTimer endTime={item.endDate} />
              {:else}
                <strong>Time to Start:</strong>
                <CountdownTimer endTime={item.startDate} />
              {/if}
              <button class="play-cta">Play for free</button>
              <button class="flip" on:click={() => flipCard(index)}
                >More information</button
              >
            </div>
          </div>
          <div class="back">
            <div class="title">
              <h2>{item.name}</h2>
              <p>Game by {item.gameProviderName}</p>
            </div>
            <div class="prize-pool">
              <h2>Total Prize Pool: {item.prizePool}</h2>
            </div>
            <div class="spins-and-frequency">
              <p><strong>Total Free Spins:</strong> {item.spins}</p>
              <p><strong>Tournament Type:</strong> {item.tournamentLabel}</p>
            </div>
            <div class="ctas">
              <button class="play-cta"> Play </button>
              <button class="flip" on:click={() => flipCard(index)}
                >Close</button
              >
            </div>
          </div>
        </div>
      {/each}
    </div>
  </div>
{/if}

<style>
  .container {
    background-color: #282828;
    color: #fff;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .admin-tournament-wrapper {
    background-color: #e8e8e8;
    padding: 20px;
    border-radius: 6px;
  }
  .tournaments-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  @media only screen and (min-width: 991px) {
    .tournaments-container {
      grid-template-columns: 1fr 1fr;
    }
  }

  .tournament-card {
    border-radius: 8px;
    overflow: hidden;
    max-width: 300px;
    margin: 20px auto;
    perspective: 1000px;
    position: relative;
    height: 500px;
    max-width: 300px;
    width: 100%;
    color: #fff;
  }
  .tournament-card .front,
  .tournament-card .back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s;
    position: absolute;
    top: 0;
    left: 0;
  }
  .tournament-card .back {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "title"
      "prize-pool"
      "spins-and-frequency"
      "ctas";
  }

  .tournament-card .back .title {
    grid-area: title;
  }
  .tournament-card .back .title h2 {
    margin: 0;
    font-size: 24px;
  }
  .tournament-card .back .title p {
    margin: 0;
    font-size: 14px;
  }
  .tournament-card .back .prize-pool {
    grid-area: prize-pool;
  }

  .tournament-card .back .prize-pool h2 {
    margin: 0;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .tournament-card .back .spins-and-frequency {
    grid-area: spins-and-frequency;
  }

  .tournament-card .back .spins-and-frequency p {
    margin: 0;
    font-size: 18px;
  }
  .tournament-card .back .ctas {
    grid-area: ctas;
  }
  .tournament-card .back .ctas .play-cta {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    padding: 4px;
    box-shadow: none;
    background-color: #626262;
  }

  .tournament-card .front {
    transform: rotateY(0deg);
    background-color: #f5f5f5;
  }
  .tournament-card .back {
    transform: rotateY(180deg);
    background-color: #000;
  }
  .tournament-card.flipped .front {
    transform: rotateY(-180deg);
  }
  .tournament-card.flipped .back {
    transform: rotateY(0deg);
  }

  .play-cta {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    font-size: 28px;
    line-height: 42px;
    border: none;
    background-color: #65c82d;
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow:
      rgba(255, 255, 255, 0.25) 0 1px 0,
      inset rgba(255, 255, 255, 0.25) 0 1px 0,
      inset rgba(0, 0, 0, 0.5) 0 0 0,
      inset rgba(255, 255, 255, 0.08) 0 1.25rem 0,
      inset rgba(0, 0, 0, 0.3) 0 -1.25rem 1.25rem,
      inset rgba(255, 255, 255, 0.1) 0 1.25rem 1.25rem;
  }

  .flip {
    color: #fff;
    margin-top: 20px;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 16px;
    background-color: transparent;
  }

  .visual,
  .gradient-overlay,
  .visual-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .gradient-overlay {
    background: linear-gradient(0deg, rgba(0, 193, 237, 0) 0%, #00c1ed 100%);
    z-index: 1;
  }
  .card-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  .tournament-label {
    font-weight: 300;
    font-size: 17px;
    line-height: 24px;
  }
  .tournament-name {
    font-size: 23px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .tournament-prize {
    font-weight: 300;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .visual-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: -1;
  }
  .visual-logo {
    max-width: 90%;
    position: relative;
    z-index: 0;
    object-fit: contain;
    margin: 0;
    display: block;
    height: 170px;
    padding-top: 10px;
  }
</style>
