import type { Tab } from "@/src/types/components/DataTable";
import type {
  Tournament,
  LeaderboardResponse,
  LeaderboardEntry,
} from "@/src/types/tournaments";
import {
  fetchTournaments,
  fetchLiveTournaments,
  fetchTournamentResults,
  fetchTournamentLeaderBoard,
  fetchFeatureTournaments,
} from "$lib/services/tournaments";

export async function fetchData(
  activeTab: number,
  tabs: Tab[],
  data: (Tournament | LeaderboardEntry | Record<string, any>)[],
  dataKeys: string[],
  isLoading: boolean,
  accordionData: Record<
    number,
    (Tournament | LeaderboardEntry | Record<string, any>)[]
  >
): Promise<{
  data: (Tournament | LeaderboardEntry | Record<string, any>)[];
  dataKeys: string[];
  isLoading: boolean;
  accordionData: Record<
    number,
    (Tournament | LeaderboardEntry | Record<string, any>)[]
  >;
}> {
  if (activeTab < 0 || activeTab >= tabs.length) {
    console.error("Invalid tab index");
    return { data, dataKeys, isLoading, accordionData };
  }

  isLoading = true;
  try {
    const currentTab = tabs[activeTab];
    if (!currentTab.endpoint) {
      console.error("No endpoint selected for the current tab");
      return { data, dataKeys, isLoading, accordionData };
    }

    const result = await getDataByEndpoint(
      currentTab.endpoint,
      currentTab.additionalArgs
    );
    const fetchedData = Array.isArray(result)
      ? result
      : (result as LeaderboardResponse).scoreboard;

    if (tabs[activeTab].isOpen) {
      accordionData[activeTab] = fetchedData;
    } else {
      data = fetchedData;
    }

    dataKeys = Object.keys(fetchedData[0] || {});

    if (currentTab.selectedColumns.length === 0) {
      currentTab.selectedColumns = dataKeys
        .slice(0, 5)
        .map((key) => ({ key, displayName: key }));
    }
    currentTab.selectedColumns = currentTab.selectedColumns.filter((col) =>
      dataKeys.includes(col.key)
    );
    currentTab.endpointSelected = true;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    isLoading = false;
  }

  return { data, dataKeys, isLoading, accordionData };
}

export async function getDataByEndpoint(
  endpoint?: string,
  additionalArgs?: { tournamentId?: number; tournamentIdList?: number }
): Promise<Tournament[] | LeaderboardResponse | LeaderboardEntry[]> {
  switch (endpoint) {
    case "live":
      return await fetchLiveTournaments();
    case "results":
      return await fetchTournamentResults();
    case "leaderboard":
      if (additionalArgs?.tournamentId !== undefined) {
        return await fetchTournamentLeaderBoard(additionalArgs.tournamentId);
      }
      throw new Error("Tournament ID is required for leaderboard endpoint");
    case "featured":
      if (additionalArgs?.tournamentIdList !== undefined) {
        return await fetchFeatureTournaments(additionalArgs.tournamentIdList);
      }
      throw new Error("Tournament ID list is required for featured endpoint");
    default:
      return await fetchTournaments();
  }
}
