<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Input from "../../../Input.svelte";
  import type { Tab } from "@/src/types/components/DataTable";

  export let tabs: Tab[];
  export let isAccordion: boolean;
  export let hideHeader: boolean;
  export let dataKeys: string[];
  export let data: any[];

  const dispatch = createEventDispatcher();

  let activeTab = 0;
  let localAdditionalArgValue: number | null = null;

  function handleFetchDataClick() {
    dispatch("fetchData", activeTab);
  }

  function setActiveTab(index: number) {
    if (index >= 0 && index < tabs.length) {
      activeTab = index;
      const currentTab = tabs[activeTab];
      if (currentTab.endpoint === "leaderboard") {
        localAdditionalArgValue =
          currentTab.additionalArgs?.tournamentId || null;
      } else if (currentTab.endpoint === "featured") {
        localAdditionalArgValue =
          currentTab.additionalArgs?.tournamentIdList || null;
      } else {
        localAdditionalArgValue = null;
      }
    } else {
      console.error("Invalid tab index");
    }
  }

  function addTab() {
    const newTab = {
      title: `Tab ${tabs.length + 1}`,
      selectedColumns: [],
      endpoint: "",
      additionalArgs: {},
      endpointSelected: false,
      isOpen: false,
    };
    tabs = [...tabs, newTab];
    dispatch("tabsChange", tabs);
    setActiveTab(tabs.length - 1);
  }

  function removeTab(index: number) {
    if (tabs.length > 1) {
      tabs = tabs.filter((_, i) => i !== index);
      dispatch("tabsChange", tabs);
      setActiveTab(Math.min(activeTab, tabs.length - 1));
    }
  }

  function handleAccordionChange(event: Event) {
    isAccordion = (event.target as HTMLInputElement).checked;
    dispatch("accordionChange", isAccordion);
  }

  function handleHideHeaderChange(event: Event) {
    hideHeader = (event.target as HTMLInputElement).checked;
    dispatch("hideHeaderChange", hideHeader);
  }

  function handleEndpointChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    tabs[activeTab].endpoint = target.value;
    tabs[activeTab].additionalArgs = {};
    localAdditionalArgValue = null;
    tabs[activeTab].endpointSelected = false;
    tabs = [...tabs];
    dispatch("tabsChange", tabs);
  }

  function handleTournamentArgumentInput(event: Event) {
    const target = event.target as HTMLInputElement;
    const value = parseInt(target.value, 10);
    localAdditionalArgValue = isNaN(value) ? null : value;
    const key =
      tabs[activeTab].endpoint === "leaderboard"
        ? "tournamentId"
        : "tournamentIdList";
    tabs[activeTab].additionalArgs = {
      ...tabs[activeTab].additionalArgs,
      [key]: localAdditionalArgValue,
    };
    tabs = [...tabs];
    dispatch("tabsChange", tabs);
  }

  function handleCheckboxChange(key: string, event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      if (dataKeys.includes(key)) {
        tabs[activeTab].selectedColumns.push({ key, displayName: key });
      }
    } else {
      tabs[activeTab].selectedColumns = tabs[activeTab].selectedColumns.filter(
        (col) => col.key !== key
      );
    }
    tabs = [...tabs];
    dispatch("tabsChange", tabs);
  }

  function handleDisplayNameChange(key: string, newDisplayName: string) {
    tabs[activeTab].selectedColumns = tabs[activeTab].selectedColumns.map(
      (col) => (col.key === key ? { ...col, displayName: newDisplayName } : col)
    );
    tabs = [...tabs];
    dispatch("tabsChange", tabs);
  }

  function handleBooleanValueChange(key: string, value: boolean | "any") {
    tabs[activeTab].selectedColumns = tabs[activeTab].selectedColumns.map(
      (col) => (col.key === key ? { ...col, booleanValue: value } : col)
    );
    tabs = [...tabs];
    dispatch("tabsChange", tabs);
  }

  function handleHiddenChange(key: string, hidden: boolean) {
    tabs[activeTab].selectedColumns = tabs[activeTab].selectedColumns.map(
      (col) => (col.key === key ? { ...col, hidden } : col)
    );
    tabs = [...tabs];
    dispatch("tabsChange", tabs);
  }

  function enableEndpointChange() {
    tabs[activeTab].endpointSelected = false;
    tabs = [...tabs];
    dispatch("tabsChange", tabs);
  }
</script>

<div class="accordion-checkbox">
  <label>
    <input
      type="checkbox"
      checked={isAccordion}
      on:change={handleAccordionChange}
    />
    Use Accordion
  </label>
  <label>
    <input
      type="checkbox"
      checked={hideHeader}
      on:change={handleHideHeaderChange}
    />
    Hide Table Header Titles
  </label>
</div>

<div class="tab-container">
  {#each tabs as tab, index}
    <div
      role="presentation"
      class={index === activeTab ? "tab-content active" : "tab-content"}
      on:click={() => setActiveTab(index)}
    >
      <Input type="text" bind:value={tab.title} />
      {#if index > 0}
        <button
          class="btn-remove"
          on:click={(e) => {
            e.stopPropagation();
            removeTab(index);
          }}>✖</button
        >
      {/if}
    </div>
  {/each}
  <button class="add-tab" on:click={addTab}>Add Tab</button>
</div>

<div class="body-content">
  <label for="endpoint">Step 1: Select the endpoint:</label>
  {#if tabs[activeTab].endpointSelected}
    <div style="background-color: green;">
      <p>Saved endpoint: {tabs[activeTab].endpoint}</p>
      <button on:click={enableEndpointChange}>Change endpoint</button>
    </div>
  {:else}
    <select
      id="endpoint"
      bind:value={tabs[activeTab].endpoint}
      on:change={handleEndpointChange}
    >
      <option value="" disabled>Select an endpoint</option>
      <option value="schedule">Tournament Schedule</option>
      <option value="live">Live Tournaments</option>
      <option value="results">Tournament Results</option>
      <option value="leaderboard">Tournament Leaderboard</option>
      <option value="featured">Feature Tournaments</option>
    </select>
    {#if tabs[activeTab].endpoint === "leaderboard" || tabs[activeTab].endpoint === "featured"}
      <div>
        <label for="additionalArg"
          >{tabs[activeTab].endpoint === "leaderboard"
            ? "Tournament ID"
            : "Tournament ID List"}</label
        >
        <input
          type="number"
          id="additionalArg"
          bind:value={localAdditionalArgValue}
          on:input={handleTournamentArgumentInput}
        />
      </div>
    {/if}
    <button
      class="btn btn-primary"
      on:click={handleFetchDataClick}
      disabled={!tabs[activeTab].endpoint}
    >
      REQUEST
    </button>
  {/if}

  {#if dataKeys.length > 0 && tabs[activeTab].endpointSelected}
    Step 2: Select the columns:
    <div class="checkbox-list">
      {#each dataKeys as key}
        <div class="column-config">
          <label>
            <input
              type="checkbox"
              on:change={(e) => handleCheckboxChange(key, e)}
              checked={tabs[activeTab].selectedColumns.some(
                (col) => col.key === key
              )}
            />
            {key}
          </label>
          {#if tabs[activeTab].selectedColumns.some((col) => col.key === key)}
            <div>
              <label for={key}>Rename Column:</label>
              <input
                id={key}
                type="text"
                value={tabs[activeTab].selectedColumns.find(
                  (col) => col.key === key
                )?.displayName || "fallback for display name"}
                on:input={(e) =>
                  handleDisplayNameChange(key, e.currentTarget.value)}
                placeholder="Display name"
              />
            </div>
            {#if typeof data[0]?.[key] === "boolean"}
              <select
                on:change={(e) =>
                  handleBooleanValueChange(
                    key,
                    e.currentTarget.value === "true"
                      ? true
                      : e.currentTarget.value === "false"
                        ? false
                        : "any"
                  )}
                value={tabs[activeTab].selectedColumns
                  .find((col) => col.key === key)
                  ?.booleanValue?.toString() || "any"}
              >
                <option value="any">Any</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
              <label>
                <input
                  type="checkbox"
                  on:change={(e) =>
                    handleHiddenChange(key, e.currentTarget.checked)}
                  checked={tabs[activeTab].selectedColumns.find(
                    (col) => col.key === key
                  )?.hidden || false}
                />
                Hide Column
              </label>
            {/if}
          {/if}
        </div>
      {/each}
    </div>
  {/if}
</div>

<style>
  /* Add AdminView specific styles here */
  .column-config {
    background: pink;
    padding: 10px;
  }
  .add-tab {
    display: flex;
    align-items: center;
  }
  .tab-content.active {
    background-color: var(--clr-pri);
  }
  .tab-container {
    display: flex;
    gap: 4px;
  }
  .tab-content {
    display: flex;
    background-color: var(--bg-clr);
    border: 1px solid var(--bg-clr);
    padding: 6px;
    border-radius: 10px;
  }
  .body-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    gap: 5px;
  }
  .checkbox-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
  }
  .btn-remove {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
</style>
