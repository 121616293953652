<script lang="ts">
  import Skeleton from "$lib/main/components/Skeleton.svelte";
  import { formatUnixDate } from "$lib/common/helpers/date";
  import type { Tab } from "@/src/types/components/DataTable";
  import { createEventDispatcher } from "svelte";

  export let tabs: Tab[];
  export let hideHeader: boolean;
  export let data: any[];
  export let isLoading: boolean;
  export let activeTab: number;

  const dispatch = createEventDispatcher();

  let skeletons = Array(5).fill({});

  function filterData(row: any, currentTab: Tab) {
    const columns = Array.isArray(currentTab.selectedColumns)
      ? currentTab.selectedColumns
      : [];

    for (const col of columns) {
      if (
        col.key in row &&
        typeof row[col.key] === "boolean" &&
        col.booleanValue !== "any" &&
        col.booleanValue !== undefined
      ) {
        if (row[col.key] !== col.booleanValue) {
          return false;
        }
      }
    }
    return true;
  }

  function getEndpointClass(endpoint?: string): string {
    return endpoint ? endpoint.toLowerCase() : "";
  }

  function setActiveTab(index: number) {
    dispatch("activeTabChange", index);
  }
</script>

<div class="content container">
  <div class="tabs-container">
    {#each tabs as tab, index}
      <div
        role="presentation"
        class={index === activeTab ? "tab active" : "tab"}
        on:click={() => setActiveTab(index)}
      >
        {tab.title}
      </div>
    {/each}
  </div>
  <table class="table-content {getEndpointClass(tabs[activeTab].endpoint)}">
    {#if !isLoading && data.length > 0}
      {#if !hideHeader}
        <thead>
          <tr class="row header">
            {#each tabs[activeTab].selectedColumns.filter((col) => !col.hidden) as column}
              <th>{column.displayName}</th>
            {/each}
            <th>Action</th>
          </tr>
        </thead>
      {/if}
      <tbody>
        {#each data.filter((row) => filterData(row, tabs[activeTab])) as row}
          <tr class="row">
            {#each tabs[activeTab].selectedColumns.filter((col) => !col.hidden) as column}
              <td>
                {#if column.key === "logoImage" && "backgroundImage" in row}
                  <div class="logo-container">
                    <img
                      class="background-image"
                      src={row.backgroundImage}
                      referrerpolicy="no-referrer"
                      alt="Background"
                    />
                    <img
                      src={row.logoImage}
                      referrerPolicy="no-referrer"
                      alt="Logo"
                      class="logo-image"
                    />
                  </div>
                {:else if column.key in row}
                  {#if typeof row[column.key] === "boolean"}
                    {row[column.key] ? "True" : "False"}
                  {:else if column.key === "startDate" || column.key === "endDate"}
                    {formatUnixDate(row[column.key])}
                  {:else}
                    {row[column.key] !== undefined ? row[column.key] : "N/A"}
                  {/if}
                {:else}
                  N/A
                {/if}
              </td>
            {/each}
            <td>
              <a href="#game">CTA BUTTON</a>
            </td>
          </tr>
        {/each}
      </tbody>
    {:else if isLoading}
      <tbody>
        {#each skeletons as _, index}
          <tr>
            <td
              colspan={tabs[activeTab].selectedColumns.filter(
                (col) => !col.hidden
              ).length + 1}
            >
              <Skeleton
                maxWidth="100%"
                height="20px"
                baseColor="rgb(23, 23, 23)"
                highlightColor="rgb(38, 38, 38)"
                customStyles="margin-bottom: 10px;"
              />
            </td>
          </tr>
        {/each}
      </tbody>
    {:else}
      <tbody>
        <tr>
          <td
            colspan={tabs[activeTab].selectedColumns.filter(
              (col) => !col.hidden
            ).length + 1}
            class="no-data"
          >
            No data available
          </td>
        </tr>
      </tbody>
    {/if}
  </table>
</div>

<style>
  .tabs-container {
    display: flex;
    background-color: #282828;
    color: white;
  }

  .tab {
    width: 180px;
    padding: 8px 0;
    text-align: center;
    border-bottom: 0;
    cursor: pointer;
  }

  .tab.active {
    border-bottom: 3px solid #cc3129;
  }
  .content {
    width: 100%;
    overflow-x: auto;
    padding: 20px 18px;
    background-color: #282828;
  }

  .table-content {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;
  }

  .table-content.schedule,
  .table-content.results {
    border-spacing: 0 6px;
  }

  .table-content.schedule .row td:first-child,
  .table-content.results .row td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid #717171;
  }

  .table-content.schedule .row td,
  .table-content.results .row td {
    border-top: 1px solid #717171;
    border-bottom: 1px solid #717171;
  }

  .table-content.schedule .row td:last-child,
  .table-content.results .row td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid #717171;
  }

  .table-content .row th,
  .table-content .row td {
    padding: 4px;
    text-align: left;
    background-color: #3f3f3f;
    color: #fff;
  }

  .table-content .row th {
    background-color: transparent;
  }

  .no-data {
    color: white;
    text-align: center;
    padding: 20px;
  }

  .logo-container {
    position: relative;
    width: 170px;
    height: 62px;
    overflow: hidden;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 95%;
    object-fit: contain;
    z-index: 2;
  }
</style>
